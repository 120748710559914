<!-- 视频中心 -->
<template>
  <div class="intoHuadu-container">
    <navSecondPageVue type="VideoCenter"></navSecondPageVue>
  </div>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component"></component>
    </keep-alive>
  </router-view>
</template>
<script setup>
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import navSecondPageVue from '../components/navSecondPage.vue'
const showTab = ref(true)

const route = useRoute()

watch(
  () => route.fullPath,
  () => {
    if (route.fullPath === '/NewsCenter/NewsDetail') {
      return
    }
    showTab.value = true
  }
)
</script>
<style lang="stylus" scoped></style>
